<template>
  <div id="partice-service">
    <breadcrumb-area :breadcrumb="breadcrumbData"/>
    <section class="ekifa-service-area section_80">
      <b-container>
        <b-row>
          <b-col>
            <div class="site-heading">
              <template>
                <h2>{{ practice.name }}</h2>
              </template>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
            </div>
          </b-col>
        </b-row>
        <div class="portfolio-warp">
          <b-row class="isotope_items">
            <b-col lg="4" md="6" v-for="(item, key) in services" :key="key">
              <div class="work-item work-image" @click="viewPraticeServices(item)">
                <div class="project-img">
                    <img :src="require(`../assets/img/${item.img}`)" alt="project">
                </div>
                <div class="content">
                  <p>{{item.name}}</p>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col>
            <div class="load-btn">
              <b-link class="ekifa-btn">load more services</b-link>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>
  
<script>
import BreadcrumbArea from "../components/BreadcrumbArea";
import { mapState, mapActions } from 'vuex'
export default {
  name: "PracticeServices",
  components: { BreadcrumbArea },
  data() {
    return {
      // breadcrumb: {
      //   page: this.$store.state.practice.name,
      //   pageTitle: 'Practice Details',
      //   pagePath: '',
      //   sequence:[
      //     {
      //       page: "Practices",
      //       pageTitle: "Practices",
      //       pagePath: "/services",
      //     },
      //     {
      //       page: this.$store.state.practice.name,
      //       pageTitle: "Practice Details",
      //     }
      //   ]
      // }
    };
  },
  created() {
    this.load(this.$route.params.idPractice)
  },
  updated() {
    this.load(this.$route.params.idPractice)
  },
  computed: {
    ...mapState(['practice', 'services']),
    breadcrumbData(){
      return {
        page: this.practice.name,
        pageTitle: this.practice.name ,
        pagePath: '',
        sequence:[
          {
            page: "Practices",
            pageTitle: "Practices",
            pagePath: "/services",
          },
          {
            page: this.practice.name,
            pageTitle: this.practice.name,
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['loadServices', 'loadPractice']),
    load(practiceName){
      debugger
      this.loadServices(practiceName)
      this.loadPractice(practiceName)
    },
    viewPraticeServices(item) {
      this.$router.push({
        name: 'PracticeServiceDetails',
        params: {
          idPractice: this.practice.name.replace(/\s+/g, ''),
          idService: item.name.replace(/\s+/g, ''),
          item: item }
      });
    }
  }
};
</script>

<style scoped>

</style>